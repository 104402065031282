import React from "react";
import { Link } from "react-scroll";
import { Typewriter } from "react-simple-typewriter";
import crypto2 from "./crypto 2.jpg";
import aos from "aos";
const Home = () => {
  return (
    <div>
      <header class="bg-white dark:bg-gray-900">
        <div class="container lg:px-[6rem] px-6 py-16 mx-auto">
          <div class="items-center lg:flex">
            <div class="w-full lg:w-1/2">
              <div class="lg:max-w-lg">
                <h1
                  class="text-3xl font-semibold text-gray-800 dark:text-white lg:text-4xl"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-once="true"
                >
                  Your gateway to <br />{" "}
                  <span className="text-3xl  lg:text-4xl font-mono text-transparent bg-clip-text bg-gradient-to-r to-green-600 from-green-800">
                    <Typewriter
                      words={["crypto knowledge.", "crypto knowledge."]}
                      loop={true}
                      cursor
                      cursorStyle="|"
                      cursorColor="green"
                      typeSpeed={90}
                    />
                  </span>
                </h1>

                <p
                  class="mt-3 text-gray-600 dark:text-gray-400 text-base"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-once="true"
                >
                  Get the latest updates on top cryptocurrencies and blockchain
                  technology at your fingertips!.
                </p>

                <div
                  class="mt-8 flex flex-wrap justify-start gap-4 "
                  data-aos="fade-up"
                  data-aos-duration="1100"
                  data-aos-once="true"
                >
                  <Link
                    to="Live price"
                    smooth
                    duration={700}
                    class="flex w-full items-center justify-center rounded bg-green-700 px-12 py-3 text-sm font-medium text-white shadow  sm:w-auto transform-all hover:scale-105 duration-200 cursor-pointer"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </div>

            <div
              class="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2"
              data-aos="zoom-out"
              data-aos-delay="500"
              data-aos-once="true"
            >
              <img
                class="w-full h-full lg:max-w-3xl"
                src={crypto2}
                alt="Catalogue-pana.svg"
              />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Home;
