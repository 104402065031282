import React, { useState, useEffect } from "react";
import axios from "axios";
const News = () => {
  const [data, setData] = useState(null);
  const url = "https://api.coingecko.com/api/v3/news";

  const fetchData = () => {
    axios
      .get(url)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // Fetch data initially
    fetchData();

    // Fetch data every 5 minutes
    const interval = setInterval(() => {
      fetchData();
    }, 5 * 60 * 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);
  const convertUnixTimestamp = (Time) => {
    const date = new Date(Time * 1000);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const time = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return `${time} / ${day} ${month} ${year}`;
  };

  if (!data) return null;
  console.log(data);
  return (
    <div
      name="news"
      className="px-4 py-16 mt-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
    >
      <div className="lg:text-4xl text-2xl lg:mb-10 mb-5 font-bold text-center">
        <span className="border-b-4 border-green-700 p-2">
          Latest Crypto News
        </span>
      </div>
      <div className="grid gap-8 lg:grid-cols-3 md:grid-cols-2 md:max-w-full sm:max-w-sm sm:mx-auto lg:max-w-full">
        {data
          .slice(0, 20)
          .filter(
            (news) =>
              news.description.split(" ").length > 15 &&
              news.thumb_2x.length > 0
          )
          .map((news, index) => (
            <div
              key={index}
              className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-lg"
              data-aos={index % 6 < 3 ? "fade-right" : "fade-left"}
              data-aos-duration="600"
              data-aos-once="true"
            >
              <a href={news.url} target="_blank">
                <img
                  src={`${news.thumb_2x}?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;w=500`}
                  className="object-cover w-full h-64 transform duration-200 hover:scale-105"
                  alt="News Img"
                />
              </a>
              <div className="p-5">
                <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
                  <span
                    href="/"
                    className="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700"
                    aria-label="Time"
                    title="Time"
                  >
                    News Time {convertUnixTimestamp(news.updated_at)}
                  </span>
                </p>
                <a
                  href="/"
                  aria-label="Category"
                  title={news.title}
                  className="inline-block mb-3 text-xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
                >
                  {news.title}
                </a>
                <p className="mb-2 text-gray-700">
                  {news.description.split(" ").slice(0, 40).join(" ")}...
                </p>
                <a
                  href={news.url}
                  aria-label=""
                  className="inline-flex items-center font-semibold transition hover:duration-300  hover:underline cursor-pointer hover:text-green-500"
                >
                  Read more
                </a>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default News;
