import React, { useState, useEffect } from "react";
import axios from "axios";

const Live_price = () => {
  const [data, setData] = useState(null);

  const url = `https://api.allorigins.win/get?url=${encodeURIComponent(
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=15&page=1&sparkline=false&locale=en"
  )}`;

  const fetchData = () => {
    axios
      .get(url)
      .then((response) => {
        const responseData = JSON.parse(response.data.contents);
        setData(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // Fetch data initially
    fetchData();

    // Fetch data every 1 minutes
    const interval = setInterval(() => {
      fetchData();
    }, 1 * 60 * 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  if (!data) return null;
  console.log(data);
  return (
    <div name="Live price" className=" lg:mt-36">
      <div className="max-w-screen-lg mx-auto p-4 w-full h-full text-white">
        <div className="md:ml-5">
          <h2 className="text-black font-semibold  text-xl md:text-2xl lg:text-3xl">
            Explore top Crypto's Like Bitcoin, Ethereum, and Dogecoin
          </h2>
          <p className="mx-0 my-6 text-black text-lg md:text-xl lg:text-2xl lg:font-base">
            See all available assets like Cryptocurrencies
          </p>
          <a
            href="https://www.coingecko.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="self-start p-2 font-medium tracking-wide text-Black transition rounded-lg shadow-md   text-white bg-green-700 border-radius hover:bg-green-800 cursor-pointer text-sm sm:text-base md:text-lg lg:text-xl"
          >
            See More Coins
          </a>
        </div>

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-5 text-center py-8 px-5 sm:px-0">
          {data.slice(0, 15).map((crypto, index) => (
            <div
              className="flex flex-col border shadow-[rgb(0_0_0_/_12%)_0px_8px_24px] text-center m-[0.4rem] px-[22px] py-3 rounded-3xl border-solid border-[#d3d3d3] transition-transform hover:scale-105"
              key={index}
              data-aos={index % 10 < 5 ? "fade-right" : "fade-left"}
            >
              <div className="flex flex-col">
                <img
                  className="w-[50px] m-auto px-0 py-[0.2rem]"
                  src={crypto.image}
                  alt=""
                />
              </div>
              <div>
                <h5 className="text-black">{crypto.name}</h5>
                <p className="text-black text-xl">
                  {"$" + crypto.current_price.toLocaleString()}
                </p>
              </div>
              {crypto.price_change_percentage_24h < 0 ? (
                <span className="text-[#ff0000] text-[20px]">
                  {crypto.price_change_percentage_24h.toFixed(2)}%
                </span>
              ) : (
                <span className="text-green-500 text-[20px]">
                  {crypto.price_change_percentage_24h.toFixed(2)}%
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Live_price;
