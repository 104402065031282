import React, { useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import Bitcoin from "./crypto.gif";
const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-full md:px-24 lg:px-8 lg:mt-1">
      <div className="relative flex items-center justify-between">
        <div className="relative flex items-center justify-start ">
          <a
            href="/"
            aria-label="MalakTel"
            title="MalakTel News"
            className="inline-flex items-center lg:ml-6"
          >
            {" "}
            <img className="w-10 h-10" src={Bitcoin} alt="Bitcoin GIF" />
            <span className="ml-2 text-2xl font-bold tracking-wide text-black">
              Malak<span className="text-green-700">Tel</span> News
            </span>
          </a>
        </div>
        <ul className="flex items-center hidden space-x-8 lg:flex">
          <li>
            <Link
              to="Home"
              smooth
              duration={700}
              aria-label="Home"
              title="Home"
              className="font-medium tracking-wide text-black cursor-pointer "
            >
              <div className="transition-transform hover:scale-105">Home</div>
            </Link>
          </li>
          <li>
            <Link
              to="Live price"
              smooth
              duration={700}
              aria-label="Live price"
              title="Live price"
              className="font-medium tracking-wide text-black transition-colors duration-200 hover:text-deep-purple-accent-400 cursor-pointer "
            >
              <div className="transition-transform hover:scale-105 ">
                Live prices
              </div>
            </Link>
          </li>
          <li>
            <Link
              to="news"
              smooth
              duration={700}
              aria-label="Crypto News"
              title="Crypto News"
              className="font-medium tracking-wide text-black transition-colors duration-200 hover:text-deep-purple-accent-400 cursor-pointer "
            >
              <div className="transition-transform hover:scale-105">
                Crypto News
              </div>
            </Link>
          </li>
          <li>
            <Link
              to="Footer"
              smooth
              duration={700}
              aria-label="About us"
              title="About us"
              className="font-medium tracking-wide text-black transition-colors duration-200 hover:text-deep-purple-accent-400 cursor-pointer "
            >
              <div className="transition-transform hover:scale-105">
                About us
              </div>
            </Link>
          </li>
        </ul>
        <div className="lg:hidden z-50">
          <button
            aria-label="Open Menu"
            title="Open Menu"
            className="p-2 mr-3 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
            onClick={() => setIsMenuOpen(true)}
          >
            <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
              />
              <path
                fill="currentColor"
                d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
              />
              <path
                fill="currentColor"
                d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
              />
            </svg>
          </button>
          {isMenuOpen && (
            <div className="absolute top-0 left-0 w-full">
              <div className="p-5 bg-white border rounded shadow-lg">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <a
                      href="/"
                      aria-label="Company"
                      title="Company"
                      className="inline-flex items-center"
                    >
                      {" "}
                      <img
                        className="w-10 h-10"
                        src={Bitcoin}
                        alt="Bitcoin GIF"
                      />
                      <span className=" text-xl font-bold tracking-wide text-black">
                        Malak<span className="text-green-700">Tel</span> News
                      </span>
                    </a>
                  </div>
                  <div>
                    <button
                      aria-label="Close Menu"
                      title="Close Menu"
                      className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <nav>
                  <ul className="space-y-4">
                    <li>
                      <Link
                        to="Home"
                        smooth
                        duration={700}
                        aria-label="Home"
                        title="Home"
                        className="font-medium tracking-wide text-black cursor-pointer text-lg"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <div className="transition-transform hover:scale-105">
                          Home
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="Live price"
                        smooth
                        duration={700}
                        aria-label="Live price"
                        title="Live price"
                        onClick={() => setIsMenuOpen(false)}
                        className="font-medium tracking-wide text-black transition-colors duration-200 hover:text-deep-purple-accent-400 cursor-pointer text-lg"
                      >
                        <div className="transition-transform hover:scale-105 ">
                          Live prices
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="news"
                        smooth
                        duration={700}
                        aria-label="Crypto News"
                        title="Crypto News"
                        onClick={() => setIsMenuOpen(false)}
                        className="font-medium tracking-wide text-black transition-colors duration-200 hover:text-deep-purple-accent-400 cursor-pointer text-lg"
                      >
                        <div className="transition-transform hover:scale-105">
                          Crypto News
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="Footer"
                        smooth
                        duration={700}
                        aria-label="About us"
                        title="About us"
                        onClick={() => setIsMenuOpen(false)}
                        className="font-medium tracking-wide text-black transition-colors duration-200 hover:text-deep-purple-accent-400 cursor-pointer text-lg"
                      >
                        <div className="transition-transform hover:scale-105">
                          About us
                        </div>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
