import React from "react";

const Footer = () => {
  return (
    <div name="Footer" className="relative mt-16 bg-green-700">
      <svg
        className="absolute top-0 w-full h-6 -mt-5 sm:-mt-10 sm:h-16 text-deep-purple-accent-400"
        preserveAspectRatio="none"
        viewBox="0 0 1440 54"
      >
        <path
          fill="rgb(21 128 61 / var(--tw-bg-opacity))"
          d="M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L1440 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z"
        />
      </svg>
      <div className="px-4 pt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-6">
          <div className="md:max-w-md lg:col-span-2">
            <a
              href="/"
              aria-label="Go home"
              title="Company"
              className="inline-flex items-center"
            >
              <span className="ml-2 text-3xl font-bold tracking-wide text-white">
                MalakTel
              </span>
            </a>
            <div className="mt-4 lg:max-w-sm">
              <p className="text-sm text-white">
                Stay updated on crypto news & live prices. Get insights,
                analysis & accurate data on top cryptocurrenciesm.
              </p>
              <p className="mt-4 text-sm text-white">
                Explore our platform for the latest in digital assets. Stay
                connected us to stay ahead in the crypto space.
              </p>
            </div>
          </div>
          <div className="flex flex-cols-2 gap-36 row-gap-8 lg:col-span-4 ml-3 lg:justify-self-end">
            <div>
              <p className="font-bold tracking-wide text-white">Our Products</p>
              <ul className="mt-2 space-y-2">
                <li>
                  <a
                    href="https://www.malaktel.com"
                    target="_blank"
                    className="transition-colors duration-300 text-gray-50 "
                  >
                    Telecom
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    target="_blank"
                    className="transition-colors duration-300 text-gray-50 "
                  >
                    Auction
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className="transition-colors duration-300 text-gray-50"
                  >
                    Crypto News
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between pt-5 pb-10 border-t border-deep-purple-accent-200 sm:flex-row">
          <p className="text-sm text-gray-100">
            © Copyright 2023 MalakTel. All rights reserved.
          </p>
          <div className="flex items-center mt-4 space-x-4 sm:mt-0 ">
            <span className="text-base text-gray-100">Contact us</span>
            <a
              href="mailto:support@malaktel-official.com"
              className="transition-colors duration-300 text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="white"
                viewBox="0 0 24 24"
              >
                <path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z" />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/malak-telecom-limited/"
              className="transition-colors duration-300 text-white"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                fill="currentColor"
                class="bi bi-linkedin"
                viewBox="0 0 16 16"
              >
                {" "}
                <path
                  d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                  fill="white"
                ></path>{" "}
              </svg>
            </a>
            <a
              href="https://www.facebook.com/groups/781173790243997/"
              className="transition-colors duration-300 text-white"
              target="_blank"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
