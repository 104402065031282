import React from "react";
import Navbar from "./Component/Navbar";
import Live_price from "./Component/Live_price";
import News from "./Component/News";
import Footer from "./Component/Footer";
import Home from "./Component/Home";
const App = () => {
  return (
    <div className="bg-white">
      <Navbar />
      <Home />
      <Live_price />
      <News />
      <Footer />
    </div>
  );
};

export default App;
